import by from "assets/images/by.jpg";
import LayoutStep from "components/common/layout/layoutStep";
// import Button from "components/ui/atoms/button/button";
// import LazyIframe from "components/common/iframe/lazyIframe";
import { titleTrainingDays } from "data/config";
import Link from "gatsby-link";
import React from "react";

import SEO from "../../components/SEO/SEO";

const Step10 = () => {
  return (
    <LayoutStep>
      <SEO
        title={`FAREWELL | ${titleTrainingDays}`}
        description={"See you soon"}
        canonical={"training-days/step-10/"}
      />
      <div className="container step">
        <p className={"h1"} id={"title"}>
          {titleTrainingDays} - Step 10{" "}
        </p>
        <h1 className={"slideLeft"}>FAREWELL</h1>
        <section className="videoCart">
          <main className={"slideLeft s05"}>
            <div className={"video--bloque--a"}></div>
            <div className={"embed-iframe"}>
              <img className={"videoCart__img"} alt={"Poster farewell"} src={by} width={"1280"} height={"720"} />
            </div>
          </main>
          <aside className={"slideLeft s1"}>
            <h3>See you soon</h3>
            <div className="stepNavigation">
              <Link to="/training-days/step-9/#top" className={"stepNavigation__button--back "}>
                {" "}
                <span className={"stepNavigation__button--back--right"}> </span> Back{" "}
              </Link>
              <Link to="/" className={"stepNavigation__button--next"}>
                {" "}
                <span className={"stepNavigation__button--next--left"}> </span> End{" "}
              </Link>
            </div>
          </aside>
        </section>
      </div>
    </LayoutStep>
  );
};

export default Step10;
